/* stylelint-disable at-rule-empty-line-before,at-rule-name-space-after,at-rule-no-unknown */
/* stylelint-disable no-duplicate-selectors */
/* stylelint-disable */
/* stylelint-disable declaration-bang-space-before,no-duplicate-selectors,string-no-newline */
.text-overflow {
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
}
.text-gradient {
  background-image: -webkit-gradient(linear, 37.219838% 34.532506%, 36.425669% 93.178216%, from(#29cdff), to(#0a60ff), color-stop(0.37, #148eff));
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}
.background-hover {
  transition: all 0.3s ease-out;
}
.background-hover:hover {
  background-color: #f9f9fc;
}
/* .ant-menu:not(.ant-menu-horizontal) .ant-menu-item-selected {
  background: transparent !important;
} */
.ant-layout-sider {
  position: fixed;
  height: 100vh;
  width: 256px;
  overflow-y: scroll;
  overflow-x: hidden;
  box-shadow: 1px 0px 20px rgba(0, 0, 0, 0.08);
  background-image: url("/static/img/sidebar-bg.png") !important;
  background-repeat: no-repeat;
  background-size: cover !important;
  background-position: 60% 100% !important;
}
.ant-layout-sider .sidebar-btn {
  padding: 4px;
  border: 1px solid #8c99e0;
  margin: 8px;
  border-radius: 20px;
  text-align: center;
  color: #8c99e0;
}
.ant-layout-sider .sidebar-profile .profile-avatar {
  text-align: center;
}
.ant-layout-sider .sidebar-profile .profile-avatar .avatar {
  border-radius: 50%;
  width: 80px;
  border: 4px solid #fff;
  box-shadow: 0px 5px 25px 0px rgba(0, 0, 0, 0.2);
  margin: 20px 0px;
}
.ant-layout-sider .sidebar-profile .profile-name {
  font-size: 18px;
  font-weight: bold;
  text-align: center;
}
.ant-layout-sider .sidebar-profile .profile-address {
  text-align: center;
  margin: 0px 25px;
  font-weight: 300px;
}
.ant-layout-sider .sidebar-profile .profile-info {
  margin-top: 30px;
  text-align: center;
}
.ant-layout-sider .sidebar-profile .profile-info .value {
  font-size: 20px;
  font-weight: 500;
  margin: 0px;
}
.ant-layout-sider .sidebar-profile .profile-info .label {
  margin: 0px;
}
#admin .ant-menu {
  background: rgba(255, 255, 255, 0.4) !important;
}
#admin .ant-col {
  width: 100% !important;
}
#admin .orderDetail .content {
  padding: 12px 24px !important;
}
#admin .trigger {
  font-size: 18px;
  line-height: 64px;
  padding: 0 24px;
  cursor: pointer;
  transition: color 0.3s;
}
#admin .google-map-container {
  height: 60vh;
  padding: 24px;
}
#admin .trigger:hover {
  color: #1890ff;
}
#admin .logo {
  height: 32px;
  background: rgba(200, 200, 200, 0.2);
  margin: 16px;
}
#admin .ant-menu-item-group-title i {
  margin-right: 5px;
}
#admin .header {
  padding: 0;
  box-shadow: 2px 2px 8px 0 rgba(0, 0, 0, 0.1);
  position: relative;
  display: block;
  height: 72px;
  z-index: 9;
  background: linear-gradient(45deg, #8b54c1 0%, #5F72BD 60%);
}
#admin .header.fixed {
  position: fixed;
  top: 0;
  right: 0;
  width: calc(100% - 256px);
  z-index: 29;
  transition: width 0.2s;
}
#admin .header .ant-menu-submenu-title {
  height: 72px;
}
#admin .header .ant-menu-horizontal {
  line-height: 72px;
}
#admin .header .ant-menu-horizontal > .ant-menu-submenu:hover {
  color: #5F72BD;
  background-color: #f9f9fc;
}
#admin .header .ant-menu {
  border-bottom: none;
  height: 72px;
}
#admin .header .ant-menu-horizontal > .ant-menu-submenu {
  top: 0;
  margin-top: 0;
}
#admin .header .ant-menu-horizontal > .ant-menu-item,
#admin .header .ant-menu-horizontal > .ant-menu-submenu {
  border-bottom: none;
}
#admin .header .ant-menu-horizontal > .ant-menu-item-active,
#admin .header .ant-menu-horizontal > .ant-menu-item-open,
#admin .header .ant-menu-horizontal > .ant-menu-item-selected,
#admin .header .ant-menu-horizontal > .ant-menu-item:hover,
#admin .header .ant-menu-horizontal > .ant-menu-submenu-active,
#admin .header .ant-menu-horizontal > .ant-menu-submenu-open,
#admin .header .ant-menu-horizontal > .ant-menu-submenu-selected,
#admin .header .ant-menu-horizontal > .ant-menu-submenu:hover {
  border-bottom: none;
}
#admin .header .header-search {
  width: 300px;
  border-radius: 20px;
}
#admin .header .rightContainer {
  position: relative;
  float: right;
}
#admin .header .header-btn {
  color: #fff;
  margin-right: 8px;
  font-weight: bold;
}
#admin .backTop {
  right: 50px;
}
#admin .backTop .ant-back-top-content {
  background: #5F72BD;
  opacity: 0.3;
  transition: all 0.3s;
  box-shadow: 0 0 15px 1px rgba(69, 65, 78, 0.1);
}
#admin .backTop .ant-back-top-content:hover {
  opacity: 1;
}
#admin .main-layout {
  margin-left: calc(256px + 0px);
  min-height: calc(100vh);
}
#admin .main-layout.collapsed {
  margin-left: 0px;
}
#admin .main-layout .breadcumb-right {
  float: right;
  background: rgba(255, 255, 255, 0.3);
  padding: 15px 20px;
  border-radius: 50px;
}
#admin .main-layout .breadcumbs {
  background: linear-gradient(45deg, #8b54c1 0%, #5F72BD 60%);
  padding: 16px 32px 100px;
}
#admin .main-layout .breadcumbs * {
  color: #fff !important;
}
#admin .main-layout .scheduleCard {
  max-width: 768px;
  margin: auto;
}
#admin .main-layout .content {
  background: #fff;
  padding: 24px;
  margin: -80px 32px 8px;
  border-radius: 4px;
}
#admin .centerBtn {
  display: block;
  margin: auto;
  margin-bottom: 16px;
}
#admin .btn-top {
  margin-bottom: 16px;
  margin-right: 8px;
}
#admin td .ant-form-item {
  margin-bottom: 0px !important;
}
#admin td .ant-form-item-control {
  line-height: normal;
}
#admin .dashboard {
  position: absolute;
  top: 40%;
  left: 40%;
  transform: translate(-40%, -40%);
  width: 100%;
}
#admin .dashboard h1 {
  font-weight: 400;
}
#admin .dashboard .ant-col {
  transition: 0.5s;
}
#admin .dashboard .ant-col i {
  font-size: 30px;
  color: #5F72BD;
}
#admin .dashboard .ant-col h4 {
  font-weight: 400;
}
#admin .dashboard .ant-col:hover {
  background-color: #d0fdd0;
}
#admin .footer {
  text-align: right;
}
.customer-search {
  top: 20px !important;
}
.customer-search .ant-modal-content {
  background-image: url("/img/cloud.jpg");
}
.customer-search .ant-modal-confirm-content {
  margin-left: inherit !important;
}
.order-assign-user .ant-modal-confirm-btns {
  display: none;
}
.order-assign-user .ant-modal-confirm-content {
  margin-left: 0 !important;
}
.ant-btn.clearBefore::before {
  top: -1px;
  left: -1px;
  right: unset;
  bottom: unset;
}
.order-tab-selected {
  background-color: #4caf50;
  /* Green */
  border: none;
  color: white;
  padding: 5px 10px;
  text-align: center;
  text-decoration: none;
  display: inline-block;
  font-size: 14px;
  border-radius: 4px;
}
.order-tab-unselected {
  background-color: #dedede;
  border: none;
  color: rgba(0, 0, 0, 0.65);
  padding: 5px 10px;
  text-align: center;
  text-decoration: none;
  display: inline-block;
  font-size: 14px;
  border-radius: 4px;
}
.temp-order-card {
  background: #00bf8f;
  /* fallback for old browsers */
  background: -webkit-linear-gradient(to right, #001510, #00bf8f);
  /* Chrome 10-25, Safari 5.1-6 */
  background: linear-gradient(to right, #001510, #00bf8f);
  /* W3C, IE 10+/ Edge, Firefox 16+, Chrome 26+, Opera 12+, Safari 7+ */
  width: 100%;
  height: 150;
}
.loadingCenter {
  text-align: center;
  background: rgba(0, 0, 0, 0.05);
  border-radius: 4px;
  margin-bottom: 20px;
  padding: 30px 50px;
  margin: 20px 0;
}
.order-next-button {
  float: right !important;
}
.whiteBg {
  background: white;
}
@media (max-width: 991px) {
  #admin {
    /* .ant-table-body {
      overflow-x: scroll;
    } */
  }
  #admin .google-map-container {
    height: 80vh;
    padding: 0px;
  }
  #admin .header {
    width: 100% !important;
  }
  #admin .main-layout {
    margin-left: 0px;
  }
  #admin .main-layout.collapsed {
    margin-left: 0px;
  }
  #admin .main-layout .content {
    padding: 16px;
    margin: -80px 8px 0px;
  }
  #admin .main-layout .scheduleCard {
    width: 100%;
  }
  #admin .backTop {
    right: 20px;
    bottom: 20px;
  }
}
@media (max-width: 575px) {
  input {
    font-size: 16px !important;
  }
  #admin .header-search {
    display: none;
  }
}

/* stylelint-disable at-rule-empty-line-before,at-rule-name-space-after,at-rule-no-unknown */
/* stylelint-disable no-duplicate-selectors */
/* stylelint-disable */
/* stylelint-disable declaration-bang-space-before,no-duplicate-selectors,string-no-newline */
.text-overflow {
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
}
.text-gradient {
  background-image: -webkit-gradient(linear, 37.219838% 34.532506%, 36.425669% 93.178216%, from(#29cdff), to(#0a60ff), color-stop(0.37, #148eff));
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}
.background-hover {
  transition: all 0.3s ease-out;
}
.background-hover:hover {
  background-color: #f9f9fc;
}
.drawer {
  top: 70px !important;
}
.ant-notification {
  z-index: 99999 !important;
}
html,
body {
  font-family: Roboto, Helvetica, sans-serif;
  scroll-behavior: smooth;
}
li:hover,
a:hover {
  border-color: none;
}
.backgroundMenuMobie {
  display: none;
}
.ant-layout {
  background: transparent;
}
.btnLoginMenu {
  border: none;
  border-radius: 50px;
  height: 40px;
  margin-top: 25px;
  width: 170px;
  background: transparent;
  font-size: 18px;
  color: #F5F6FA;
  text-transform: uppercase;
  letter-spacing: 1.05px;
  text-align: center;
  background: #54D8A3 0% 0% no-repeat padding-box;
  box-shadow: 0px 10px 6px #54D8A329;
  cursor: pointer;
  margin-right: 50px;
}
.btnLoginMenu a {
  color: white;
}
.buttonHead {
  display: table;
  margin: 0 auto;
  margin-top: 50px;
  z-index: 1000;
}
.btnLogin {
  border: 2px solid #F5F6FA;
  border-radius: 50px;
  height: 56px;
  width: 205px;
  background: transparent;
  font-size: 21px;
  color: #F5F6FA;
  text-transform: uppercase;
  letter-spacing: 1.05px;
  text-align: center;
  cursor: pointer;
  margin-right: 20px;
}
.BtnStartTest {
  border: none;
  border-radius: 50px;
  height: 56px;
  width: 205px;
  background: transparent;
  font-size: 21px;
  color: #F5F6FA;
  text-transform: uppercase;
  letter-spacing: 1.05px;
  text-align: center;
  background: #54D8A3 0% 0% no-repeat padding-box;
  box-shadow: 0px 10px 6px #54D8A329;
  cursor: pointer;
}
.container {
  width: 100%;
  margin: 0 auto;
}
@media (min-width: 480px) {
  .container {
    max-width: 480px;
  }
}
@media (min-width: 576px) {
  .container {
    max-width: 576px;
  }
}
@media (min-width: 768px) {
  .container {
    max-width: 768px;
  }
}
@media (min-width: 992px) {
  .container {
    max-width: 992px;
  }
}
@media (min-width: 1200px) {
  .container {
    max-width: 1200px;
  }
}
#headerMenu {
  z-index: 2000;
}
#headerMenu .customerMenu {
  background: transparent !important;
  border: none;
  color: #F5F6FA;
  text-transform: uppercase;
  font-size: 16px;
  margin-top: 20px;
  margin-bottom: 20px;
  display: inline-block;
}
#headerMenu .logo img {
  width: 10%;
  float: left;
  margin-top: -10px;
  cursor: pointer;
}
#headerMenu .customerMenu a {
  color: white;
}
#headerMenu .menuPc {
  position: fixed;
  width: 100%;
  top: 0;
  left: 0;
  right: 0;
}
#headerMenu .transparent {
  background: transparent;
}
#headerMenu .whitebg {
  background: white;
  height: 85px;
  box-shadow: 0px 0px 6px #5f72bd;
  z-index: 99999;
}
#headerMenu .whitebg a {
  color: #5F72BD;
  font-weight: 500;
}
#headerMenu .ant-menu-item-selected {
  color: white;
  border: none;
}
#headerMenu .customSelectLang .ant-select-selector {
  margin-top: 5px;
  background: transparent;
  border: none;
  color: #F5F6FA;
  margin-top: 30px;
}
#headerMenu .customSelectLang .ant-select-arrow {
  margin-top: 5px;
  color: white;
}
#headerMenu .bannerImag1 img {
  position: absolute;
  left: 517px;
  width: 36.3%;
  border: 3px solid #2d2d2d;
  border-radius: 18px;
}
#headerMenu .bannerImag2 img {
  position: absolute;
  top: 42.5%;
  left: 373px;
  width: 4%;
  border: 2px solid #2d2d2d;
  border-radius: 12px;
}
#headerMenu .bannerImag3 img {
  float: right;
  margin-top: 17%;
  margin-right: 31px;
  width: 10.7%;
  border: 1px solid #2d2d2d;
}
#headerMenu .headerMenu-logo {
  max-width: 100%;
  width: 80px;
  vertical-align: top;
  display: inline-block;
}
#headerMenu .menuMobile-header {
  position: fixed;
  width: 100%;
  top: 0;
  left: 0;
  right: 0;
  background: white;
  height: 70px;
  box-shadow: 0px 0px 6px #5f72bd;
}
#headerMenu .menuMobile-header img {
  height: 70px;
  max-height: 100%;
  display: inline-block;
}
#headerMain {
  background-size: cover;
  height: 780px;
  margin-bottom: 15%;
  background: url('/static/img/background.png');
}
#headerMain .TextContent h1 {
  color: #F5F6FA;
  letter-spacing: 1.4px;
  text-transform: uppercase;
  text-align: center;
  margin-top: 200px;
  font-size: 24px;
  font-weight: bold;
}
#headerMain .TextContent p {
  color: #F5F6FA;
  text-align: center;
  margin-top: 40px;
  font-size: 16px;
  letter-spacing: 1.05px;
}
#headerMain .bannerImageHeadMqn img {
  width: 100%;
  display: table;
  margin: 0 auto;
  margin-top: -80px;
}
#headerMain .imgHeaderMainMobi img {
  display: none !important;
}
#bannerBodyMain {
  background: white;
}
#bannerBodyMain .content h1 {
  margin-top: 170px !important;
  text-align: center;
  margin-bottom: 2%;
  font-weight: bold;
  letter-spacing: 1.7px;
}
#contentInfoBannerMqn {
  margin-top: 50px;
}
#contentInfoBannerMqn .contentLeft {
  margin-top: 5%;
  margin-left: 3%;
}
#contentInfoBannerMqn .contentLeft h3 {
  text-align: left;
  font-size: 16px;
  letter-spacing: 1.28px;
  color: #54D8A3;
  text-transform: uppercase;
  margin-top: 5%;
}
#contentInfoBannerMqn .contentLeft h1 {
  text-align: left;
  font-size: 22px;
  font-weight: bold;
  letter-spacing: 0.2px;
  color: #663399;
  text-transform: uppercase;
}
#contentInfoBannerMqn .contentLeft h5 {
  text-align: left;
  font-size: 18px;
  letter-spacing: 1.44px;
  color: #43425D;
}
#contentInfoBannerMqn .lineheight {
  width: 53px;
  height: 2px;
  background: #54D8A3 0% 0% no-repeat padding-box;
  margin-bottom: 20px;
}
#contentInfoBannerMqn .contentLeft p {
  text-align: left;
  font-size: 14px;
  letter-spacing: 0px;
  color: #43425D;
}
#contentInfoBannerMqn .contentLeft button {
  background: #663399 0% 0% no-repeat padding-box;
  box-shadow: 0px 10px 6px #66339929;
  border-radius: 50px;
  width: 170px;
  height: 45px;
  text-align: center;
  font-size: 18px;
  letter-spacing: 1.12px;
  font-weight: bold;
  color: #F5F6FA;
  text-transform: uppercase;
  border: none;
  margin-top: 20px;
  outline: none;
  cursor: pointer;
}
#contentInfoBannerMqn .linehigtGrey {
  background: #E9E9F0;
  height: 2px;
  margin-top: 3%;
  margin-bottom: 3%;
}
#reviewMain {
  background: url(/static/img/bg4.jpg) no-repeat padding-box;
  background-size: cover;
  opacity: 0.76;
  padding: 50px;
  margin-top: 90px;
}
#reviewMain .titleTextTop h1 {
  text-align: center;
  font-size: 20px;
  letter-spacing: 1.6px;
  color: #F1F1F3;
  font-weight: bold;
  text-transform: uppercase;
}
#reviewMain .titleTextTop h2 {
  text-align: center;
  font-size: 32px;
  letter-spacing: 2.56px;
  color: #F1F1F3;
  text-transform: uppercase;
  font-weight: bold;
  margin-top: 20px;
  margin-bottom: 50px;
}
#reviewMain .contentTextReview h1 {
  text-align: center;
  font-size: 30px;
  color: #F5F6FA;
  margin-bottom: -32px;
  padding: 32px 0px;
}
#reviewMain .contentTextReview h2 {
  text-align: center;
  font-size: 16px;
  color: #F5F6FA;
  opacity: 1;
}
#reviewMain .backborder {
  background: #5E6EC5;
  width: 180px;
  height: 180px;
  border-radius: 50%;
  display: table;
  margin: 0 auto;
}
#reviewMain .backPro {
  background: #677ADE 0% 0% no-repeat padding-box;
  width: 160px;
  height: 160px;
  border-radius: 50%;
  position: relative;
  top: 10px;
  left: 10px;
}
#reviewMain .contentTextReview {
  text-align: center;
  background: #798AE5 0% 0% no-repeat padding-box;
  width: 140px;
  height: 140px;
  border-radius: 50%;
  display: table;
  margin: 0 auto;
  opacity: 1;
  position: relative;
  top: 10px;
}
#feature {
  margin-top: 100px;
}
#feature .titleFeature h1 {
  text-align: center;
  font-size: 20px;
  font-weight: bold;
  letter-spacing: 1.6px;
  color: #54D8A3;
  text-transform: uppercase;
}
#feature .titleFeature h2 {
  text-align: center;
  font-size: 32px;
  font-weight: bold;
  letter-spacing: 2.56px;
  color: #663399;
  text-transform: uppercase;
}
#feature .contentFeature {
  margin-top: 60px;
}
#feature .itemContentFeature h1 {
  text-align: left;
  font-size: 17px;
  letter-spacing: 1.44px;
  color: #663399;
  text-transform: uppercase;
  font-weight: bold;
  margin-bottom: 4px;
}
#feature .itemContentFeature p {
  text-align: left;
  font-size: 14px;
  color: #43425D;
  line-height: 18px;
}
#feature .contentItemFeature {
  margin-bottom: 80px;
}
#feature .contentItemFeature img {
  float: left;
  margin: 5px 20px 0px 0px;
}
#priceTablePro {
  margin-top: 70px;
}
#priceTablePro .titleMainPrice h1 {
  text-align: center;
  font-size: 20px;
  letter-spacing: 1.6px;
  color: #54D8A3;
  text-transform: uppercase;
  font-weight: bold;
}
#priceTablePro .titleMainPrice h2 {
  text-align: center;
  font-size: 32px;
  letter-spacing: 2.56px;
  font-weight: bold;
  color: #663399;
  text-transform: uppercase;
  margin-bottom: 30px;
}
#priceTablePro .itemTablePriceProMqn {
  background: #FFFFFF 0% 0% no-repeat padding-box;
  box-shadow: 0px 10px 6px #0000000A;
  border: 1px solid #E9E9F0;
  width: 100%;
  display: table;
  margin: 0 auto;
}
#priceTablePro .activeTablePrice {
  background: transparent linear-gradient(180deg, #663399 0%, #5F72BD 100%) 0% 0% no-repeat padding-box;
  border: 1px solid #E9E9F0;
  color: #F5F6FA;
  box-shadow: 0px 2px 13px #752cbd;
}
#priceTablePro .activeTablePrice .titleTypePack h3,
#priceTablePro .activeTablePrice .titleTypePack h2,
#priceTablePro .activeTablePrice .titleTypePack h1 {
  color: #F5F6FA;
}
#priceTablePro .titleTypePack h1 {
  text-align: center;
  font-size: 20px;
  letter-spacing: 0px;
  color: #4D4F5C;
  text-transform: uppercase;
  font-weight: bold;
  margin-top: 15px;
}
#priceTablePro .titleTypePack h2 {
  text-align: center;
  font-size: 32px;
  font-weight: bold;
  letter-spacing: 0px;
  color: #43425D;
  font-family: Cambria, Cochin, Georgia, Times, 'Times New Roman', serif, Helvetica, sans-serif;
}
#priceTablePro .titleTypePack h3 {
  text-align: center;
  font-size: 15px;
  letter-spacing: 0px;
  color: #707070;
  margin-bottom: 18px;
}
#priceTablePro .contentItempack {
  border-top: 1px solid #E9E9F0;
  padding: 20px 10px;
  text-align: center;
  min-height: 820px;
  height: 100%;
}
#priceTablePro .titleTypePack img {
  float: right;
  right: 10px;
  position: absolute;
  top: 10px;
}
#priceTablePro .contentItempack button {
  background: #663399 0% 0% no-repeat padding-box;
  box-shadow: 0px 10px 6px #66339929;
  border: none;
  border-radius: 50px;
  width: 180px;
  height: 45px;
  text-align: center;
  font-size: 18px;
  letter-spacing: 1.05px;
  color: #F5F6FA;
  text-transform: uppercase;
  font-weight: bold;
  cursor: pointer;
  outline: none;
}
#priceTablePro .activeTablePrice .contentItempack button {
  background: #54D8A3 0% 0% no-repeat padding-box;
  box-shadow: 0px 10px 6px #54D8A329;
  border: none;
  border-radius: 50px;
  opacity: 1;
  cursor: pointer;
  outline: none;
}
#contactTestStart {
  background: url(/static/img/try_now_bg.png) no-repeat;
  background-size: cover;
  height: 300px;
  margin-top: 80px;
}
#contactTestStart .contentContactTestStart h1 {
  text-align: center;
  font-size: 20px;
  letter-spacing: 1.6px;
  font-weight: bold;
  color: #F1F1F3;
  text-transform: uppercase;
  margin-top: 65px;
  margin-bottom: 20px;
}
#contactTestStart .contentContactTestStart h2 {
  text-align: center;
  font-size: 32px;
  letter-spacing: 3.2px;
  font-weight: bold;
  color: #F1F1F3;
  text-transform: uppercase;
  margin-bottom: 20px;
}
#contactTestStart .contentContactTestStart button {
  background: #54D8A3 0% 0% no-repeat padding-box;
  box-shadow: 0px 10px 6px #54D8A329;
  border: none;
  border-radius: 50px;
  color: #F5F6FA;
  font-size: 18px;
  font-weight: bold;
  width: 180px;
  height: 45px;
  display: table;
  margin: 0 auto;
  text-transform: uppercase;
  cursor: pointer;
  outline: none;
}
#FooterMain {
  background-color: #F5F6FA;
}
#FooterMain .mt50 {
  margin-top: 50px;
}
#FooterMain .itemFooterPro {
  margin-top: -30px;
}
#FooterMain .itemFooterPro img {
  width: 20%;
  max-width: 100% !important;
  margin-left: -5%;
}
#FooterMain .itemFooterPro h1 {
  text-align: left;
  font-size: 40px;
  letter-spacing: 2px;
  color: #43425D;
  text-transform: uppercase;
  margin-bottom: 10px;
}
#FooterMain .itemFooterPro p {
  text-align: left;
  font-size: 16px;
  color: #43425D;
  width: 400px;
}
#FooterMain .menuFooterMqn h1 {
  text-align: left;
  font-size: 24px;
  font-weight: bold;
  margin-bottom: 20px;
  color: #54D8A3;
  text-transform: uppercase;
  margin-top: 10px;
}
#FooterMain .ant-menu-vertical {
  border-right: none;
  background: transparent !important;
}
#FooterMain .menuFooterMqn ul li {
  text-align: left;
  font-size: 16px;
  letter-spacing: 0px;
  color: #43425D;
  margin-bottom: 0px;
  margin-left: -12px;
  border: none;
}
#FooterMain .customFormFooter {
  width: 235px;
  height: 35px;
  background: #FFFFFF;
  box-shadow: 0px 0px 15px #00000029;
  border-radius: 50px;
  border: none;
  margin-bottom: 20px;
}
#FooterMain .customFormSubmit {
  width: 62px;
  height: 35px;
  background: #663399;
  border-radius: 50px;
  border: none;
  position: relative;
  left: -60px;
}
#FooterMain .itemContactInfo img {
  float: left;
  margin: 0px 10px 0px 0px;
  width: 17px;
}
#FooterMain .itemContactInfo p {
  font-size: 14px;
  letter-spacing: 0.7px;
  color: #43425D;
}
#FooterMain .copyrightFooter {
  border-top: 1px solid #CECFD0;
  padding: 25px 0px;
  margin-top: 20px;
}
#FooterMain .iconSocial {
  float: right;
}
#FooterMain .iconSocial img {
  margin-right: 20px;
}
#aboutMain {
  background: url('/static/img/imgHeaderAbout.PNG') no-repeat;
  height: 611px;
  background-size: cover;
}
#aboutMain .contentTitleHeaderAbout {
  display: table;
  margin: 0 auto;
  margin-top: 250px;
}
#aboutMain .contentTitleHeaderAbout h1 {
  text-align: center;
  font-size: 28px;
  font-weight: bold;
  letter-spacing: 1.4px;
  color: #F5F6FA;
  text-transform: uppercase;
  margin-bottom: 30px;
}
#aboutMain .contentTitleHeaderAbout p {
  text-align: center;
  font-size: 16px;
  letter-spacing: 0.8px;
  color: #F5F6FA;
  margin-bottom: 30px;
}
#itemAboutMainDetail {
  margin-top: 100px;
}
#itemAboutMainDetail .itemContentDetailMain h1 {
  text-align: left;
  font-size: 16px;
  font-weight: bold;
  letter-spacing: 1.28px;
  color: #54D8A3;
  text-transform: uppercase;
}
#itemAboutMainDetail .itemContentDetailMain h2 {
  text-align: left;
  font-size: 24px;
  font-weight: bold;
  letter-spacing: 1.92px;
  color: #663399;
  text-transform: uppercase;
}
#itemAboutMainDetail .lineBlueHeader {
  width: 53px;
  height: 2px;
  margin-bottom: 20px;
  background: #54D8A3 0% 0% no-repeat padding-box;
}
#itemAboutMainDetail .itemContentDetailMain p {
  text-align: left;
  font-size: 14px;
  line-height: 28px;
  margin-bottom: 20px;
  color: #43425D;
}
#itemAboutMainDetail .itemImgAboutDetail img {
  width: 90%;
}
#itemBorderRabowLeft {
  background: url('/static/img/border1.PNG') no-repeat;
}
#itemBorderRabowLeft .titleSevercieMain {
  display: table;
  margin: 0 auto;
  margin-top: 100px;
}
#itemBorderRabowLeft .titleSevercieMain h1 {
  text-align: center;
  font-size: 16px;
  font-weight: Bold;
  letter-spacing: 1.28px;
  color: #54D8A3;
  text-transform: uppercase;
}
#itemBorderRabowLeft .titleSevercieMain h2 {
  text-align: center;
  font-size: 24px;
  font-weight: Bold;
  margin-bottom: 20px;
  letter-spacing: 1.92px;
  color: #663399;
  text-transform: uppercase;
}
#itemBorderRabowLeft .itemServiceAbout img {
  display: table;
  margin: 0 auto;
  margin-top: 30px;
  width: 180px;
  height: 180px;
  object-fit: cover;
}
#itemBorderRabowLeft .itemServiceAbout p {
  text-align: center;
  font-size: 18px;
  letter-spacing: 0px;
  color: #43425D;
  width: 300px;
  display: table;
  margin: 0 auto;
  margin-bottom: 30px;
  text-transform: uppercase;
  line-height: 35px;
  margin-top: 15px;
}
#aboutMeMain {
  background: url(/static/img/background_video@2x.png) no-repeat;
  background-size: 100% 100%;
  height: auto;
  min-height: 440px;
  margin-top: 50px;
}
#aboutMeMain .videoYoutube iframe {
  margin-top: 30px;
  border: none;
}
#aboutMeMain .itemContentLeft h1 {
  text-align: left;
  font-size: 20px;
  letter-spacing: 1.6px;
  font-weight: bold;
  color: #F5F6FA;
  text-transform: uppercase;
}
#aboutMeMain .itemContentLeft h2 {
  text-align: left;
  font-size: 30px;
  color: #F5F6FA;
  text-transform: uppercase;
  font-weight: bold;
}
#aboutMeMain .itemContentLeft p {
  text-align: left;
  font-size: 16px;
  color: #F5F6FA;
}
#aboutMeMain .itemContentLeft button {
  background: #54D8A3 0% 0% no-repeat padding-box;
  box-shadow: 0px 10px 6px #54D8A329;
  border: none;
  border-radius: 50px;
  color: #F5F6FA;
  font-size: 18px;
  font-weight: bold;
  width: 170px;
  height: 45px;
  cursor: pointer;
  outline: none;
  margin-top: 10px;
}
#aboutMeMain .itemContentLeft {
  margin-top: 75px;
}
#aboutMeMain .itemContentLeft ul {
  margin-left: -42px;
}
#aboutMeMain .itemContentLeft ul li {
  list-style-type: none;
}
#aboutMeMain .itemContentLeft img {
  margin-right: 10px;
  background: #54d8a3;
  border-radius: 50%;
  height: 16px;
  width: 16px;
  border: 1px solid #54d8a3;
}
#aboutMeMain .itemContentLeft li {
  font-size: 14px;
  letter-spacing: 0px;
  color: #F5F6FA;
  margin-bottom: 12px;
}
#aboutMeMain .top50 {
  margin-top: 50px;
}
#aboutMeMain .btnAboutTest {
  background: #54D8A3 0% 0% no-repeat padding-box;
  box-shadow: 0px 10px 6px #54D8A329;
  border: 2px solid #4CECAC;
  border-radius: 50px;
  font-weight: bold;
  font-size: 21px;
  text-align: center;
  letter-spacing: 1.05px;
  color: #F5F6FA;
  text-transform: uppercase;
  width: 205px;
  height: 56px;
  margin-top: 15px;
}
#border2Main {
  background: url(/static/img/borderRight.PNG) no-repeat;
  height: 250px;
  margin-bottom: -50px;
  background-position: right;
}
#seachEmailAboutMain {
  display: table;
  margin: 0 auto;
  margin-top: 80px;
}
#seachEmailAboutMain .itemContentSeachEmail h1 {
  text-align: center;
  font-size: 24px;
  font-weight: bold;
  letter-spacing: 1.92px;
  color: #663399;
  text-transform: uppercase;
}
#seachEmailAboutMain .itemContentSeachEmail p {
  text-align: center;
  font-size: 14px;
  line-height: 25px;
  color: #43425D;
  margin-top: 20px;
}
#seachEmailAboutMain .itemContentSeachEmail img {
  width: 100%;
  max-width: 70%;
  display: block;
  margin: 0 auto;
  margin-top: 50px;
}
#itemHeaderContactMain {
  height: 609px;
  background: url('/static/img/imgHeaderContact.PNG') no-repeat;
  background-size: cover;
}
#itemHeaderContactMain .itemTitleCustomer {
  display: table;
  margin: 0 auto;
  margin-top: 180px;
}
#itemHeaderContactMain .itemTitleCustomer h1 {
  text-align: center;
  font-weight: Bold;
  font-size: 24px;
  letter-spacing: 1.4px;
  color: #F5F6FA;
  text-transform: uppercase;
  margin-bottom: 30px;
}
#itemHeaderContactMain .itemTitleCustomer p {
  text-align: center;
  font-size: 16px;
  letter-spacing: 0.8px;
  color: #F5F6FA;
}
#ContactMainMqn {
  margin-top: 80px;
}
#ContactMainMqn .itemInfoContactMain h1 {
  text-align: left;
  font-size: 20px;
  font-weight: bold;
  letter-spacing: 1.6px;
  color: #43425D;
  text-transform: uppercase;
  margin-bottom: 15px;
}
#ContactMainMqn .itemInfoContactMain p {
  text-align: left;
  font-size: 14px;
  line-height: 25px;
  letter-spacing: 0px;
  color: #43425D;
  width: 500px;
  margin-bottom: 15px;
}
#ContactMainMqn .itemInfoContactMain h2 {
  text-align: left;
  font-size: 16px;
  font-weight: bold;
  letter-spacing: 1.28px;
  color: #663399;
  text-transform: uppercase;
}
#ContactMainMqn .itemInfoContactMain ul li {
  list-style-type: none;
  margin-left: -40px;
  margin-bottom: 15px;
}
#ContactMainMqn .itemInfoContactMain img {
  float: left;
  margin-right: 10px;
}
#ContactMainMqn .itemFormContactMain {
  padding: 24px 24px;
}
#ContactMainMqn .itemFormContactMain input {
  width: 100%;
}
#ContactMainMqn .itemFormContactMain TextArea {
  width: 100%;
  height: 100px;
}
#ContactMainMqn .itemFormContactMain button {
  background: #663399 0% 0% no-repeat padding-box;
  box-shadow: 0px 3px 6px #66339929;
  border-radius: 50px;
  float: right;
  width: 179px;
  height: 49px;
  text-align: center;
  font-size: 18px;
  font-weight: bold;
  letter-spacing: 0.9px;
  color: #F5F6FA;
  text-transform: uppercase;
}
#googleMap {
  margin-top: 50px;
}
#bannerSeverMqnMainProContact {
  margin-top: 50px;
}
#bannerSeverMqnMainProContact .itemSeverContact img {
  display: table;
  margin: 0 auto;
  width: 62%;
  height: 225px;
  object-fit: cover;
}
#bannerSeverMqnMainProContact .itemSeverContact p {
  text-align: center;
  font-size: 16px;
  letter-spacing: 0px;
  color: #43425D;
  margin-top: 10px;
  font-weight: bold;
}
#customerHeaderProMain {
  height: 609px;
  background: url('/static/img/headerImgCustomer.PNG') no-repeat;
  background-size: cover;
}
#customerHeaderProMain .itemTitleCustomer {
  display: table;
  margin: 0 auto;
  margin-top: 180px;
}
#customerHeaderProMain .itemTitleCustomer h1 {
  text-align: center;
  font-weight: Bold;
  font-size: 28px;
  letter-spacing: 1.4px;
  color: #F5F6FA;
  text-transform: uppercase;
  margin-bottom: 30px;
}
#customerHeaderProMain .itemTitleCustomer p {
  text-align: center;
  font-size: 16px;
  letter-spacing: 0.8px;
  color: #F5F6FA;
}
#itemFeedbackCustomer {
  margin-top: 100px;
}
#itemFeedbackCustomer .itemSlideCustomerReview img {
  width: 100%;
}
#itemFeedbackCustomer .itemTitleFeedback h1 {
  text-align: center;
  font-size: 20px;
  letter-spacing: 1.6px;
  color: #54D8A3;
  text-transform: uppercase;
  font-weight: bold;
}
#itemFeedbackCustomer .itemTitleFeedback h2 {
  text-align: center;
  font-size: 23px;
  font-weight: bold;
  letter-spacing: 2.56px;
  color: #663399;
  text-transform: uppercase;
  margin-bottom: 40px;
}
#itemFeedbackCustomer .ant-carousel .slick-slide {
  text-align: center;
  overflow: hidden;
  height: 450px;
}
#itemFeedbackCustomer .ant-carousel .slick-slide h3 {
  color: #fff;
}
#itemFeedbackCustomer .itemAvataMainCustomer img {
  width: 100%;
  margin-top: 10px;
  height: 335px;
  object-fit: cover;
}
#itemFeedbackCustomer .itemInfoMainCustomerFeedback {
  text-align: left;
}
#itemFeedbackCustomer .itemInfoMainCustomerFeedback ul li {
  display: inline-block;
  margin-right: 5px;
  list-style-type: none;
}
#itemFeedbackCustomer .itemInfoMainCustomerFeedback h1 {
  text-align: left;
  font-size: 12px;
  font-weight: bold;
  letter-spacing: 0.96px;
  color: #A4AFB7;
  text-transform: uppercase;
  float: left;
  margin-right: 50px;
  margin-left: 12px;
}
#itemFeedbackCustomer .itemInfoMainCustomerFeedback span {
  font-size: 14px;
  font-weight: bold;
  letter-spacing: 0.56px;
  color: #54D8A3;
  margin-left: 15px;
  position: relative;
  top: -2px;
}
#itemFeedbackCustomer .itemInfoMainCustomerFeedback p {
  text-align: left;
  font-size: 16px;
  line-height: 32px;
  color: #43425D;
}
#itemFeedbackCustomer .itemNameCustomer h1 {
  text-align: center;
  font-size: 16px;
  font-weight: bold;
  letter-spacing: 1.28px;
  color: #663399;
  text-transform: uppercase;
  margin-top: 40px;
}
#itemFeedbackCustomer .itemNameCustomer h2 {
  text-align: center;
  font-size: 12px;
  font-weight: bold;
  letter-spacing: 0.96px;
  color: #4CECAC;
  text-transform: uppercase;
}
#itemFeedbackCustomer .ant-carousel .slick-dots li.slick-active button {
  width: 15px;
  height: 15px;
  position: relative;
  top: 0px;
  border-radius: 50%;
  background: transparent linear-gradient(180deg, #663399 0%, #5F72BD 100%) 0% 0% no-repeat padding-box;
}
#itemFeedbackCustomer .ant-carousel .slick-dots li button {
  width: 15px;
  height: 15px;
  position: relative;
  top: 0px;
  border-radius: 50%;
  background: white;
  background: transparent linear-gradient(180deg, #663399 0%, #5F72BD 100%) 0% 0% no-repeat padding-box;
}
#itemPartnerCustomer {
  margin-top: 70px;
}
#itemPartnerCustomer .titlePartnerCustomer h1 {
  text-align: center;
  font-size: 32px;
  font-weight: Bold;
  letter-spacing: 2.56px;
  color: #663399;
  text-transform: uppercase;
}
#bannerBodyFeature {
  height: 609px;
  background: transparent linear-gradient(269deg, #663399 0%, #5F72BD 100%) 0% 0% no-repeat padding-box;
}
#bannerBodyFeature .MainHeaderFeature {
  position: relative;
  top: 150px;
}
#bannerBodyFeature .imgHeaderFeature img {
  width: 100%;
}
#bannerBodyFeature .contentHeaderFeature h1 {
  text-align: left;
  font-size: 26px;
  font-weight: bold;
  letter-spacing: 2.08px;
  color: #F5F6FA;
  text-transform: uppercase;
  margin-bottom: 20px;
  margin-top: 30px;
}
#bannerBodyFeature .lineBlueHeader {
  width: 53px;
  height: 2px;
  margin-bottom: 20px;
  background: #54D8A3 0% 0% no-repeat padding-box;
}
#bannerBodyFeature .contentHeaderFeature p {
  text-align: left;
  font-size: 14px;
  letter-spacing: 0px;
  color: #FFFFFF;
  line-height: 28px;
  margin-bottom: 20px;
}
#bannerBodyFeature .contentHeaderFeature button {
  background: #54D8A3 0% 0% no-repeat padding-box;
  box-shadow: 0px 10px 6px #54D8A329;
  border: 2px solid #4CECAC;
  border-radius: 50px;
  width: 205px;
  height: 56px;
  text-align: center;
  font-size: 21px;
  font-weight: bold;
  letter-spacing: 1.05px;
  color: #F5F6FA;
  text-transform: uppercase;
}
#aboutSystemFeature {
  margin-top: 80px;
}
#aboutSystemFeature .itemContentRightSystem h1 {
  text-align: left;
  font-size: 26px;
  font-weight: bold;
  letter-spacing: 2.08px;
  line-height: 45px;
  color: #663399;
  text-transform: uppercase;
}
#aboutSystemFeature .itemContentRightSystem p {
  text-align: left;
  font-size: 14px;
  line-height: 21px;
  letter-spacing: 0px;
  color: #43425c;
}
#aboutSystemFeature .imgLeftSystem {
  background: url(/static/img/minhAbout.png) no-repeat;
  height: 485px;
  width: 100%;
  position: relative;
  background-size: 100% 100%;
  left: -170px;
}
#aboutSystemFeature .imgLeftSystem img {
  width: 704px;
  position: relative;
  left: 75px;
  top: 3px;
  border: 4px solid black;
  border-radius: 12px;
}
#aboutSystemFeature .lineBlueHeader {
  width: 53px;
  height: 2px;
  margin-bottom: 20px;
  background: #54D8A3 0% 0% no-repeat padding-box;
}
#solutionSystemContent {
  margin-top: 100px;
}
#solutionSystemContent .contentTitleSolution h1 {
  text-align: center;
  font-size: 20px;
  font-weight: bold;
  margin-bottom: 10px;
  letter-spacing: 1.6px;
  color: #54D8A3;
  text-transform: uppercase;
}
#solutionSystemContent .contentTitleSolution h2 {
  text-align: center;
  font-size: 32px;
  margin-bottom: 30px;
  letter-spacing: 2.56px;
  color: #663399;
  font-weight: bold;
  text-transform: uppercase;
}
#solutionSystemContent .ant-carousel .slick-slide {
  text-align: center;
  line-height: 160px;
  overflow: hidden;
  height: 730px;
}
#solutionSystemContent .ant-carousel .slick-slide h3 {
  color: #fff;
}
#solutionSystemContent .itemSliderSystemSolution {
  position: relative;
}
#solutionSystemContent .itemSliderSystemSolution img {
  width: 100%;
}
#solutionSystemContent #solutionSystemContent .slick-slide li {
  height: 15px;
  width: 15px;
  border-radius: 50%;
}
#solutionSystemContent .ant-carousel .slick-dots li.slick-active button {
  width: 15px;
  height: 15px;
  position: relative;
  top: 0px;
  border-radius: 50%;
  background: transparent linear-gradient(180deg, #663399 0%, #5F72BD 100%) 0% 0% no-repeat padding-box;
}
#solutionSystemContent .ant-carousel .slick-dots li button {
  width: 15px;
  height: 15px;
  position: relative;
  top: 0px;
  border-radius: 50%;
  background: white;
  background: transparent linear-gradient(180deg, #663399 0%, #5F72BD 100%) 0% 0% no-repeat padding-box;
}
#solutionSystemContent .itemBannerSystem {
  position: absolute;
  bottom: 300px;
  right: 80px;
  background: #FFFFFF 0% 0% no-repeat padding-box;
  box-shadow: 0px 3px 6px #00000029;
  width: 545px;
  padding: 0px 20px;
  height: 100px;
}
#solutionSystemContent .ContentImages h1 {
  text-align: left;
  font-size: 14px;
  font-weight: bold;
  letter-spacing: 1.12px;
  color: #54D8A3;
  text-transform: uppercase;
  position: relative;
  top: -50px;
}
#solutionSystemContent .ContentImages h2 {
  text-align: left;
  font-size: 26px;
  font-weight: bold;
  letter-spacing: 2.08px;
  color: #663399;
  text-transform: uppercase;
  position: relative;
  top: -175px;
}
#solutionSystemContent .content {
  bottom: 130px;
  height: 150px;
}
#solutionSystemContent .ContentImages h5 {
  text-align: left;
  font-size: 12px;
  font-weight: bold;
  letter-spacing: 0.96px;
  color: #A4AFB7;
  text-transform: uppercase;
  margin-bottom: 20px;
  position: relative;
  top: -55px;
}
#solutionSystemContent .ContentImages p {
  text-align: left;
  font-size: 14px;
  letter-spacing: 0px;
  color: #43425D;
  position: relative;
  top: -135px;
  line-height: 22px;
}
#HeaderPriceMain {
  background: url('/static/img/headerPrice.PNG') no-repeat;
  height: 618px;
  background-size: cover;
}
#HeaderPriceMain .itemContentHeaderPrice {
  margin-top: 180px;
}
#HeaderPriceMain .itemContentHeaderPrice h1 {
  text-align: center;
  font-size: 28px;
  letter-spacing: 1.4px;
  color: #F5F6FA;
  text-transform: uppercase;
  font-weight: bold;
}
#HeaderPriceMain .itemContentHeaderPrice p {
  text-align: center;
  font-size: 16px;
  margin-top: 40px;
  letter-spacing: 0.8px;
  color: #F5F6FA;
}
#itemPriceAllMqn {
  margin-top: 100px;
}
#itemPriceAllMqn .titleItemTalbePrice {
  display: table;
  margin: 0 auto;
}
#itemPriceAllMqn .titleItemTalbePrice h1 {
  text-align: center;
  font-size: 20px;
  font-weight: bold;
  letter-spacing: 1.6px;
  color: #54D8A3;
  text-transform: uppercase;
}
#itemPriceAllMqn .titleItemTalbePrice h2 {
  text-align: center;
  font-size: 32px;
  font-weight: bold;
  letter-spacing: 2.56px;
  color: #663399;
  text-transform: uppercase;
  margin-bottom: -20px;
}
#itemPriceAllMqn .itemPriceTable1 {
  margin-top: 50px;
  background: transparent linear-gradient(180deg, #663399 0%, #5F72BD 100%) 0% 0% no-repeat padding-box;
  border-radius: 5px;
  width: 99%;
  padding: 20px 40px;
  color: white;
  height: 100%;
  margin-bottom: 10px;
  margin-left: 8px;
}
#itemPriceAllMqn .itemPriceInfo h1 {
  text-align: center;
  font-size: 40px;
  font-weight: bold;
  letter-spacing: 3.2px;
  color: #F5F6FA;
  text-transform: uppercase;
  margin-bottom: 30px;
}
#itemPriceAllMqn .itemPriceInfo p {
  text-align: center;
  font-size: 14px;
  font-weight: bold;
  letter-spacing: 1.12px;
  color: #F5F6FA;
  margin-bottom: 30px;
}
#itemPriceAllMqn .itemPriceInfo h3 {
  text-align: center;
  font-size: 60px;
  font-weight: bold;
  letter-spacing: 4.8px;
  color: #F5F6FA;
  margin-bottom: 30px;
}
#itemPriceAllMqn .itemPriceInfo button {
  background: #54D8A3 0% 0% no-repeat padding-box;
  box-shadow: 0px 10px 6px #54D8A329;
  border: 2px solid #4CECAC;
  border-radius: 50px;
  width: 205px;
  height: 56px;
  text-align: center;
  font-size: 21px;
  letter-spacing: 1.05px;
  color: #F5F6FA;
  font-weight: bold;
  text-transform: uppercase;
  display: table;
  margin: 0 auto;
}
#itemPriceAllMqn .itemInfoContentMain {
  margin-left: 80px;
  margin-top: 20px;
}
#itemPriceAllMqn .itemInfoContentMain p {
  margin-bottom: 22px;
}
#itemPriceAllMqn .itemInfoContentMain img {
  margin-right: 10px;
}
#itemPriceAllMqn .itemTablePriceProMqn {
  background: #FFFFFF 0% 0% no-repeat padding-box;
  box-shadow: 0px 10px 6px #0000000A;
  border: 1px solid #E9E9F0;
  width: 100%;
}
#itemPriceAllMqn .activeTablePrice {
  background: transparent linear-gradient(180deg, #663399 0%, #5F72BD 100%) 0% 0% no-repeat padding-box;
  box-shadow: 0px 2px 13px #752cbd;
  border: 1px solid #E9E9F0;
  color: #F5F6FA;
}
#itemPriceAllMqn .activeTablePrice .titleTypePack h3,
#itemPriceAllMqn .activeTablePrice .titleTypePack h2,
#itemPriceAllMqn .activeTablePrice .titleTypePack h1 {
  color: #F5F6FA;
}
#itemPriceAllMqn .titleTypePack h1 {
  text-align: center;
  font-size: 20px;
  letter-spacing: 0px;
  color: #4D4F5C;
  text-transform: uppercase;
  font-weight: bold;
  margin-top: 15px;
}
#itemPriceAllMqn .titleTypePack h2 {
  text-align: center;
  font-size: 85px;
  letter-spacing: 0px;
  color: #43425D;
  font-family: Cambria, Cochin, Georgia, Times, 'Times New Roman', serif, Helvetica, sans-serif;
}
#itemPriceAllMqn .titleTypePack h3 {
  text-align: center;
  font-size: 15px;
  letter-spacing: 0px;
  color: #707070;
  margin-bottom: 18px;
}
#itemPriceAllMqn .contentItempack {
  border-top: 1px solid #E9E9F0;
  padding: 20px 10px;
  text-align: center;
}
#itemPriceAllMqn .contentItempack button {
  background: #663399 0% 0% no-repeat padding-box;
  box-shadow: 0px 10px 6px #66339929;
  border: none;
  border-radius: 50px;
  width: 180px;
  height: 45px;
  text-align: center;
  font-size: 18px;
  letter-spacing: 1.05px;
  color: #F5F6FA;
  text-transform: uppercase;
  font-weight: bold;
  cursor: pointer;
  outline: none;
}
.itemHeaderSupport img {
  margin-top: -60px;
  width: 100%;
}
#itemSystemAllTutorial {
  display: table;
  margin: 0 auto;
  margin-top: 80px;
}
#itemSystemAllTutorial .itemTitleAllTutorial h1 {
  text-align: center;
  font-size: 20px;
  font-weight: bold;
  letter-spacing: 1.6px;
  color: #54D8A3;
  text-transform: uppercase;
}
#itemSystemAllTutorial .itemTitleAllTutorial h2 {
  text-align: center;
  font-size: 32px;
  font-weight: bold;
  letter-spacing: 2.56px;
  color: #663399;
  text-transform: uppercase;
  opacity: 1;
}
#itemSystemAllTutorial .itemTutorial {
  margin-bottom: 50px;
}
#itemSystemAllTutorial .itemTutorial img {
  width: 50%;
  display: table;
  margin: 0 auto;
}
#itemSystemAllTutorial .itemAllTutorial {
  margin-top: 50px;
}
#itemSystemAllTutorial .itemTutorial h1 {
  text-align: center;
  font-size: 20px;
  font-weight: bold;
  letter-spacing: 1.6px;
  color: #663399;
  text-transform: uppercase;
}
#itemSystemAllTutorial .itemTutorial h1 > a {
  color: #663399;
}
#itemSystemAllTutorial .itemTutorial h1 > a:hover {
  color: #8595c9;
}
#itemSystemAllTutorial .itemTutorial p {
  text-align: center;
  font-size: 14px;
  font-weight: bold;
  letter-spacing: 1.12px;
  color: #43425D;
  width: 186px;
  display: table;
  margin: 0 auto;
}
#detailInfoContentSupportMqn {
  margin-top: 80px;
}
#detailInfoContentSupportMqn .titleSupportDetail h1 {
  text-align: left;
  font-size: 32px;
  font-weight: bold;
  letter-spacing: 2.56px;
  color: #663399;
  text-transform: uppercase;
}
#detailInfoContentSupportMqn .tagDetailSupport {
  width: 145px;
  min-width: fit-content;
  height: 36px;
  padding: 7px 10px;
  background: #F1F1F3 0% 0% no-repeat padding-box;
  border: 1px solid #663399;
  border-radius: 50px;
  margin-top: 5px;
}
#detailInfoContentSupportMqn .tagDetailSupport h1 {
  text-align: center;
  font-size: 13px;
  font-weight: bold;
  letter-spacing: 1.04px;
  color: #663399;
  margin-bottom: 20px;
}
#detailInfoContentSupportMqn .contentDetailSupportMqn {
  margin-top: 50px;
}
#detailInfoContentSupportMqn .contentDetailSupportMqn p {
  font-size: 14px;
  color: #43425D;
  line-height: 25px;
  margin-bottom: 20px;
}
#questionMainProMqn {
  margin-top: 50px;
}
#questionMainProMqn .titleQestionMain h1 {
  margin-top: 50px;
  text-align: left;
  font-size: 32px;
  font-weight: Bold;
  letter-spacing: 2.56px;
  color: #663399;
  text-transform: uppercase;
}
#questionMainProMqn .titleQestionMain img {
  position: relative;
  top: 25px;
  left: 10px;
}
#questionMainProMqn .ant-collapse > .ant-collapse-item > .ant-collapse-header {
  font-weight: bold;
}
.titleQestionMain h1 {
  float: left;
}
#headerTrialMain {
  height: 609px;
  background: url('/static/img/headerImgCustomer.PNG') no-repeat;
  background-size: cover;
  margin-bottom: 80px;
}
#headerTrialMain .contentTitleHeaderAbout {
  display: table;
  margin: 0 auto;
  margin-top: 150px;
}
#headerTrialMain .contentTitleHeaderAbout h1 {
  text-align: center;
  font-size: 28px;
  font-weight: bold;
  letter-spacing: 1.4px;
  color: #F5F6FA;
  text-transform: uppercase;
  margin-bottom: 30px;
}
#headerTrialMain .contentTitleHeaderAbout p {
  text-align: center;
  font-size: 16px;
  letter-spacing: 0.8px;
  color: #F5F6FA;
}
#headerTrialMain .FormInfoUserTrial {
  background: white;
  box-shadow: 0px 0px 6px grey;
  display: table;
  margin: 0 auto;
  margin-bottom: 200px;
  border-radius: 5px;
  margin-top: 30px;
  width: 100%;
}
#headerTrialMain .itemFormContactMain {
  padding: 24px;
}
#headerTrialMain .itemFormContactMain TextArea {
  width: 100%;
  height: 100px;
}
#headerTrialMain .itemFormContactMain button {
  background: #663399 0% 0% no-repeat padding-box;
  box-shadow: 0px 3px 6px #66339929;
  border-radius: 50px;
  float: right;
  width: 179px;
  height: 49px;
  text-align: center;
  font-size: 18px;
  font-weight: bold;
  letter-spacing: 0.9px;
  color: #F5F6FA;
  text-transform: uppercase;
  margin-top: 50px;
}
#headerTrialMain .itemTrialSuccessMain img {
  margin: 0 auto;
  display: table;
  width: 15%;
  margin-top: 20px;
}
#headerTrialMain .itemTrialSuccessMain h1 {
  text-align: center;
  font-size: 26px;
  font-weight: bold;
  letter-spacing: 2.08px;
  line-height: 45px;
  color: #663399;
  text-transform: uppercase;
  margin-top: 30px;
}
#headerTrialMain .itemTrialSuccessMain p {
  text-align: center;
  font-size: 14px;
  color: #43425D;
  margin-top: 20px;
}
#headerTrialMain .itemTrialSuccessMain button {
  margin: 0 auto !important;
  margin-top: 30px !important;
  display: table !important;
  width: auto;
  float: none;
  height: 50px;
  margin-bottom: 20px !important;
}
.comparison {
  margin: 0 auto;
  font: 13px/1.4 "Helvetica Neue", Helvetica, Arial, sans-serif;
  text-align: center;
  padding: 10px;
}
.comparison table {
  width: 100%;
  border-collapse: collapse;
  border-spacing: 0;
  table-layout: fixed;
  border-bottom: 1px solid #CCC;
}
.comparison td,
.comparison th {
  border-right: 1px solid #CCC;
  empty-cells: show;
  padding: 10px;
}
.compare-heading {
  font-size: 18px;
  font-weight: bold !important;
  border-bottom: 0 !important;
  padding-top: 10px !important;
}
.comparison tbody tr:nth-child(odd) {
  background: white;
}
.comparison tbody tr:nth-child(even) {
  background: #f0f0f0;
}
.comparison .compare-row {
  background: #F5F5F5;
}
.comparison .tickblue {
  color: #0078C1;
}
.comparison .tickgreen {
  color: #009E2C;
}
.comparison th {
  font-weight: normal;
  padding: 0;
  border-bottom: 1px solid #CCC;
}
.comparison tr td:first-child {
  text-align: left;
}
.comparison .qbse,
.comparison .qbo,
.comparison .tl,
.comparison .violet {
  color: #FFF;
  padding: 10px;
  font-size: 13px;
  border-right: 1px solid #CCC;
  border-bottom: 0;
}
.comparison .tl2 {
  border-right: 0;
}
.comparison .qbse {
  background: #0078C1;
  border-top-left-radius: 3px;
  border-left: 0px;
}
.comparison .qbo {
  background: #009E2C;
  border-top-right-radius: 3px;
  border-right: 0px;
}
.comparison .violet {
  background: #65359a;
  border-top-right-radius: 3px;
  border-right: 0px;
}
.comparison .price-info {
  padding: 5px 15px 15px 15px;
}
.comparison .price-was {
  color: #999;
  text-decoration: line-through;
}
.comparison .price-now,
.comparison .price-now span {
  color: #ff5406;
  padding-top: 14px;
}
.comparison .price-now span {
  font-size: 25px;
}
.comparison .price-small {
  font-size: 18px !important;
  position: relative;
  top: -11px;
  left: 2px;
}
.comparison .price-buy {
  background: #ff5406;
  padding: 10px 20px;
  font-size: 12px;
  display: inline-block;
  color: #FFF;
  text-decoration: none;
  border-radius: 3px;
  text-transform: uppercase;
  margin: 12px 0 10px 0;
}
.comparison .price-try {
  font-size: 12px;
}
.comparison .price-try a {
  color: #202020;
}
@media (max-width: 767px) {
  .comparison td:first-child,
  .comparison th:first-child {
    display: none;
  }
  .comparison tbody tr:nth-child(odd) {
    display: table-row;
    background: #F7F7F7;
  }
  .comparison .row {
    background: #FFF;
  }
  .comparison td,
  .comparison th {
    border: 1px solid #CCC;
  }
  .price-info {
    border-top: 0 !important;
  }
}
@media (max-width: 639px) {
  .comparison .price-buy {
    padding: 5px 10px;
  }
  .comparison td,
  .comparison th {
    padding: 10px 5px;
  }
  .comparison .hide-mobile {
    display: none;
  }
  .comparison .price-now span {
    font-size: 16px;
  }
  .comparison .price-small {
    font-size: 16px !important;
    top: 0;
    left: 0;
  }
  .comparison .qbse,
  .comparison .qbo {
    font-size: 12px;
    padding: 10px 5px;
  }
  .comparison .price-buy {
    margin-top: 10px;
  }
  .compare-heading {
    font-size: 13px;
  }
}
.iconFuild {
  width: 40% !important;
  margin-top: 35px !important;
  margin-bottom: 7px !important;
}
.ant-menu {
  background-color: transparent !important;
}
.card-horizonal {
  display: flex;
  height: 160px;
  overflow: hidden;
  margin: 16px 0px;
}
.card-horizonal .ant-card-cover {
  width: 30%;
}
.card-horizonal .ant-card-cover img {
  height: 100%;
  width: 100%;
  max-width: 100%;
}
.card-horizonal .ant-card-body {
  width: 70%;
}
.card-horizonal .ant-card-body .ant-card-meta-description :first-child {
  color: #5F72BD !important;
  text-transform: uppercase;
}
.slick-next:before {
  content: " . ";
  color: white;
  background: url(/static/img/next.svg) no-repeat !important;
  position: relative;
  z-index: 9999;
  width: 54px;
  font-size: 200px;
}
.slick-prev:before {
  content: " . ";
  color: white;
  background: url(/static/img/back.svg) no-repeat !important;
  position: relative;
  z-index: 9999;
  width: 54px;
  font-size: 200px;
}
.slick-prev {
  left: -60px;
}
@media (max-width: 1366px) {
  #headerMain .bannerImageHead {
    display: none;
  }
  #headerMain .imgHeaderMainMobi img {
    display: block;
    margin: 0 auto;
    margin-top: 50px;
  }
  #aboutSystemFeature .imgLeftSystem {
    background-size: cover;
    height: 550px;
  }
  #aboutSystemFeature .imgLeftSystem img {
    width: 553px;
  }
}
@media (max-width: 1199px) {
  .bannerImageHeadMqn {
    display: none;
  }
  #headerMain {
    height: 600px;
  }
  #headerMain .bannerImageHead {
    display: none;
  }
  #bannerBodyMain {
    margin-top: 90px;
  }
  #bannerBodyMain .content img {
    width: 80%;
  }
  #contentInfoBannerMqn .contentRight img {
    margin-top: 30px;
  }
  #feature .titleFeature h2,
  #reviewMain .titleTextTop h2 {
    font-size: 25px;
    margin-top: 10px;
  }
  #feature .itemContentFeature h1 {
    font-size: 14px;
  }
  #contactTestStart .contentContactTestStart h2 {
    font-size: 32px;
  }
  #FooterMain .customFormFooter {
    width: auto;
  }
  #FooterMain .itemFooterPro p {
    padding: 30px;
    margin-top: -20px;
    width: 100%;
    padding-left: 0px;
  }
  #FooterMain .itemContactInfo img {
    margin: 2px 10px 0px 0px;
  }
  #contentInfoBannerMqn .contentLeft h1 {
    font-size: 26px;
  }
  #headerMain .imgHeaderMainMobi img {
    display: block;
    width: 45%;
    margin: 0 auto;
    margin-top: 40px;
  }
  #bannerBodyMain .content h1 {
    margin-top: 80px;
  }
  #bannerBodyFeature .contentHeaderFeature h1 {
    font-size: 24px;
  }
  #bannerBodyFeature .contentHeaderFeature p {
    line-height: 24px;
  }
  #aboutSystemFeature .imgLeftSystem {
    display: table;
    margin: 0 auto;
    width: 100%;
    background-position: center;
    margin-bottom: -100px;
    background-size: cover;
    height: 550px;
    margin-bottom: 30px;
    left: 0;
  }
  #aboutSystemFeature .itemContentRightSystem h1 {
    text-align: center;
  }
  #aboutSystemFeature .lineBlueHeader {
    display: table;
    margin: 0 auto;
  }
  #aboutSystemFeature .itemContentRightSystem p {
    padding: 0px 60px;
  }
  #solutionSystemContent .ant-carousel .slick-slide {
    height: 650px;
  }
  #itemPriceAllMqn .itemPriceInfo h1 {
    font-size: 32px;
  }
  #itemPriceAllMqn .itemInfoContentMain {
    margin-left: 20px;
  }
  #itemPriceAllMqn .itemInfoContentMain p {
    padding: 0px 10px;
  }
  #ContactMainMqn {
    margin-top: 40px;
    padding: 0px 20px;
  }
  #ContactMainMqn .itemInfoContactMain p {
    width: 400px;
  }
  #itemHeaderContactMain .itemTitleCustomer h1 {
    font-size: 22px;
  }
  #detailInfoContentSupportMqn .titleSupportDetail h1 {
    font-size: 26px;
  }
  .btnLoginMenu {
    margin-left: -60px;
    height: 40px;
    width: 135px;
    font-size: 16px;
  }
}
@media (max-width: 991px) {
  #headerMain {
    height: 600px;
  }
  #headerMain .bannerImageHead {
    display: none;
  }
  #headerMain .customerMenu {
    font-size: 14px;
    margin-left: 10px;
  }
  #headerMain .TextContent h1 {
    margin-top: 145px;
  }
  #bannerBodyMain .content img {
    width: 70%;
    display: table;
    margin: 0 auto;
  }
  #bannerBodyMain .content h1 {
    margin-top: 0px !important;
    font-size: 18px;
  }
  #contentInfoBannerMqn .contentRight img {
    margin-top: 30px;
  }
  #feature {
    margin-top: 40px;
    margin-bottom: -80px;
  }
  #feature .titleFeature h2,
  #reviewMain .titleTextTop h2 {
    font-size: 25px;
    margin-top: 10px;
  }
  #reviewMain {
    padding: 10px;
  }
  #feature .itemContentFeature h1 {
    font-size: 24px;
  }
  #feature .itemContentFeature p {
    font-size: 22px;
    line-height: 36px;
  }
  #contactTestStart .contentContactTestStart h2 {
    font-size: 26px;
  }
  #priceTablePro .itemTablePriceProMqn {
    width: 100%;
  }
  #FooterMain {
    padding: 20px;
  }
  #FooterMain .customFormFooter {
    width: 600px;
  }
  #contentInfoBannerMqn .contentLeft h1 {
    font-size: 26px;
  }
  #headerMain .imgHeaderMainMobi img {
    display: block !important;
    width: 45%;
    margin: 0 auto;
    margin-top: 40px;
  }
  #aboutMeMain .itemContentLeft h2 {
    text-align: left;
    font-size: 24px;
    letter-spacing: 2.56px;
    color: #F5F6FA;
    text-transform: uppercase;
    font-weight: bold;
  }
  #aboutMeMain .itemContentLeft {
    padding: 0px 24px;
  }
  #aboutMeMain .btnAboutTest {
    margin-left: 20px;
  }
  #aboutMeMain .videoYoutube {
    width: 95%;
  }
  #seachEmailAboutMain .itemContentSeachEmail p {
    padding: 0px 24px;
  }
  .ant-menu-item {
    padding: 0 7px;
  }
  #contentInfoBannerMqn .linehigtGrey {
    margin-top: 10%;
    margin-bottom: 10%;
  }
  .backgroundMneuMobie {
    background: white;
    margin-top: -20px;
    padding: 10px 10px;
  }
  .imgHeaderFeature {
    display: none;
  }
  .contentHeaderFeature {
    padding: 0px 40px;
  }
  #bannerBodyFeature {
    height: 475px;
  }
  #feature .contentItemFeature img {
    width: 15%;
  }
  #feature .itemContentFeature p {
    font-size: 22px;
  }
  #feature .itemContentFeature h1 {
    font-size: 22px;
  }
  #aboutSystemFeature .itemContentRightSystem h1 {
    padding: 0px 40px;
  }
  #aboutSystemFeature .imgLeftSystem {
    width: 87%;
  }
  #aboutSystemFeature .imgLeftSystem img {
    width: 89%;
    height: 290px;
    top: 44px;
    margin-bottom: 250px;
    background-size: 100% 100%;
    left: 0;
  }
  #aboutSystemFeature .imgLeftSystem {
    background-size: 100%;
  }
  #solutionSystemContent .contentTitleSolution h2 {
    font-size: 28px;
  }
  #solutionSystemContent .itemBannerSystem {
    bottom: 250px;
  }
  #solutionSystemContent .content {
    bottom: 75px;
  }
  #solutionSystemContent .ant-carousel .slick-slide {
    height: 500px;
  }
  #itemBorderRabowLeft .itemServiceAbout p {
    width: 250px;
  }
  .itemContentDetailMain {
    padding: 0px 24px;
  }
  #itemAboutMainDetail .itemImgAboutDetail img {
    width: 100%;
  }
  #itemBorderRabowLeft .itemServiceAbout p {
    width: 200px;
  }
  #itemAboutMainDetail {
    margin-top: 0px;
  }
  #itemPriceAllMqn .titleItemTalbePrice h2 {
    padding: 0px 32px;
  }
  #HeaderPriceMain .itemContentHeaderPrice p {
    padding: 0px 32px;
  }
  .itemStartBlue {
    float: right;
  }
  #itemFeedbackCustomer .ant-carousel .slick-slide {
    height: 100%;
  }
  #itemFeedbackCustomer .itemInfoMainCustomerFeedback {
    padding: 0px 20px;
  }
  #itemFeedbackCustomer .itemTitleFeedback h2 {
    font-size: 24px;
    padding: 0px 20px;
  }
  #itemFeedbackCustomer .itemTitleFeedback h1 {
    font-size: 14px;
  }
  #itemPartnerCustomer .titlePartnerCustomer h1 {
    font-size: 24px;
  }
  #itemPartnerCustomer .itemLogoPartner img {
    width: 100%;
  }
  #customerHeaderProMain .itemTitleCustomer h1 {
    padding: 0px 32px;
  }
  #customerHeaderProMain .itemCustomerReview {
    position: relative;
    width: 100%;
  }
  #customerHeaderProMain .contentCusomerReivew {
    position: absolute;
  }
  .itemHeaderSupport img {
    display: none;
  }
  #ContactMainMqn {
    margin-top: 40px;
    padding: 0px 20px;
  }
  #ContactMainMqn .itemInfoContactMain p {
    width: 100%;
  }
  #itemHeaderContactMain {
    height: 430px;
  }
  #itemHeaderContactMain .itemTitleCustomer {
    margin-top: 130px;
  }
  #detailInfoContentSupportMqn .titleSupportDetail h1 {
    font-size: 18px;
    letter-spacing: 1.56px;
  }
  #detailInfoContentSupportMqn .contentDetailSupportMqn {
    margin-top: 20px;
  }
  #detailInfoContentSupportMqn .tagDetailSupport {
    width: 125px;
    margin-top: 0px;
  }
  #detailInfoContentSupportMqn .tagDetailSupport h1 {
    letter-spacing: 0px;
    font-size: 12px;
  }
  #detailInfoContentSupportMqn {
    padding: 0px 24px;
    margin-top: 30px;
  }
  .btnLoginMenu {
    margin-left: -50px;
  }
  #headerTrialMain .FormInfoUserTrial {
    width: 90%;
  }
  #headerMenu .customerMenu {
    font-size: 14px;
  }
}
@media (max-width: 767px) {
  #FooterMain .customFormFooter {
    width: 284px;
  }
  #FooterMain .iconSocial {
    margin-left: 30px;
  }
  #FooterMain .itemFooterPro p {
    padding-left: 0px;
    padding-right: 0px;
  }
  #FooterMain .itemFooterPro img {
    width: 30%;
    margin-left: -7% !important;
  }
  .BtnStartTest,
  .btnLogin {
    width: 140px !important;
    height: 45px !important;
    font-size: 16px !important;
  }
  #headerMain .buttonHead {
    margin-top: 10px !important;
  }
  #headerMain .bannerImageHeadMqn img {
    width: 100% !important;
    margin-top: 40px !important;
  }
  #reviewMain {
    background-size: cover !important;
    height: auto !important;
  }
  #reviewMain .backborder {
    margin-right: -4px !important;
    margin-left: 20px !important;
  }
  #headerMain .TextContent h1,
  #priceTablePro .titleMainPrice h2,
  .itemContentLeft,
  #feature .titleFeature h2,
  #reviewMain .titleTextTop h2 {
    margin-bottom: 20px;
    font-size: 18px;
    padding: 0px 10px;
  }
  #headerMain .TextContent h1 {
    font-size: 24px;
  }
  #feature .titleFeature h1,
  #reviewMain .titleTextTop h1 {
    font-size: 14px;
    margin-top: 20px;
  }
  #feature .itemContentFeature h1 {
    font-size: 16px;
  }
  #feature .contentItemFeature img {
    margin: 15px 20px 0px 0px;
  }
  #aboutMeMain {
    background-size: cover !important;
    margin-top: 120px !important;
    padding-bottom: 50px !important;
  }
  #aboutMeMain .itemContentLeft {
    margin-left: 10px !important;
    margin-top: 30px !important;
  }
  #aboutMeMain .videoYoutube {
    width: 100% !important;
    height: 100% !important;
  }
  #priceTablePro .titleMainPrice h2 {
    font-size: 22px;
  }
  #priceTablePro {
    margin-top: 30px;
  }
  #priceTablePro .itemTablePriceProMqn {
    width: 370px;
    margin-left: 20px;
  }
  #contactTestStart .contentContactTestStart h1 {
    font-size: 16px;
  }
  .itemContactInfo {
    margin-left: 65px;
  }
  #aboutMeMain .itemContentLeft button,
  #contentInfoBannerMqn .contentLeft button {
    display: table;
    margin: 0 auto;
  }
  .menuPc {
    display: none !important;
  }
  .backgroundMneuMobie {
    display: block !important;
    position: fixed;
    z-index: 1;
    width: 100%;
    box-shadow: 0px 0px 6px #b3adad;
  }
  .ant-layout.ant-layout-has-sider {
    margin-top: -20px;
    margin-bottom: -330px;
  }
  .ant-layout-sider-zero-width-trigger {
    top: 0px;
  }
  .ant-menu.ant-menu-dark {
    position: relative;
    z-index: 999999;
    height: 100vh !important;
  }
  .ant-menu-dark.ant-menu-dark:not(.ant-menu-horizontal) .ant-menu-item-selected {
    top: -5px;
  }
  #bannerBodyFeature .MainHeaderFeature {
    top: 70px;
  }
  #bannerBodyFeature {
    background-size: 100% 100%;
    height: 609px;
  }
  #aboutSystemFeature {
    margin-top: 0px !important;
  }
  #aboutSystemFeature .imgLeftSystem {
    left: 0;
  }
  #aboutSystemFeature .imgLeftSystem img {
    height: 238px;
    width: 91%;
    top: 85px;
    border-radius: 12px;
  }
  #aboutSystemFeature .itemContentRightSystem h1 {
    font-size: 24px;
    line-height: 32px;
    padding: 0px 10px;
  }
  #aboutSystemFeature .lineBlueHeader {
    margin-bottom: 14px;
  }
  #feature .contentItemFeature img {
    width: 25%;
  }
  #feature {
    margin-bottom: -130px;
  }
  #solutionSystemContent .contentTitleSolution h1 {
    font-size: 14px;
  }
  #solutionSystemContent .contentTitleSolution h2 {
    font-size: 24px;
    line-height: 38px;
    padding: 0px 20px;
  }
  #solutionSystemContent .ant-carousel .slick-slide {
    height: 400px;
  }
  #solutionSystemContent .content {
    width: 100%;
    height: 185px;
    right: 0;
  }
  #solutionSystemContent .itemBannerSystem .content {
    display: none;
  }
  #solutionSystemContent .ContentImages h1 {
    font-size: 12px;
  }
  #solutionSystemContent .ContentImages h2 {
    font-size: 18px;
  }
  #solutionSystemContent .content {
    bottom: -75px;
  }
  .MenuMobie {
    padding: 14px 0px;
  }
  #aboutMain .contentTitleHeaderAbout {
    margin-top: 150px;
  }
  #aboutMain .contentTitleHeaderAbout p {
    padding: 0px 10px;
  }
  #aboutMain button {
    margin-left: 0px;
  }
  #itemAboutMainDetail .itemContentDetailMain h2 {
    font-size: 16px;
  }
  #itemAboutMainDetail .itemImgAboutDetail img {
    margin-left: 50px;
  }
  #aboutMeMain .btnAboutTest {
    display: table;
    margin: 0 auto;
    margin-bottom: 30px;
  }
  #aboutMeMain {
    background-size: cover;
    height: 620px;
  }
  #seachEmailAboutMain {
    margin-top: 30px;
  }
  #seachEmailAboutMain .itemContentSeachEmail h1 {
    font-size: 18px;
  }
  #seachEmailAboutMain .itemContentSeachEmail p {
    padding: 0px 15px;
  }
  #HeaderPriceMain .itemContentHeaderPrice h1 {
    padding: 0px 24px;
  }
  #bannerSeverMqnMainProContact .itemSeverContact img {
    width: auto;
  }
}
@media (max-width: 767px) {
  #reviewMain .backborder {
    margin: 0 auto !important;
    margin-bottom: 30px !important;
  }
  #reviewMain {
    padding: 0;
  }
  #aboutMeMain {
    height: 100%;
  }
  #aboutMeMain .videoYoutube iframe {
    width: auto;
    height: 225px !important;
    margin-left: 45px;
  }
  #priceTablePro .itemTablePriceProMqn {
    width: 100%;
    margin-left: 0px;
    margin-bottom: 30px;
  }
  #FooterMain {
    margin-left: 0px;
    padding: 16px;
  }
  #FooterMain .customFormFooter {
    width: 235px;
  }
  #contactTestStart .contentContactTestStart h2 {
    font-size: 18px;
  }
  .itemContactInfo {
    margin-left: 14px;
  }
  .contentHeaderFeature h1 {
    font-size: 18px;
  }
  #feature .contentItemFeature {
    margin-bottom: 40px;
    padding: 0px 10px;
  }
  #feature .itemContentFeature h1 {
    font-size: 14px;
  }
  #feature .itemContentFeature p {
    font-size: 14px;
  }
  #solutionSystemContent {
    margin-top: 45px;
  }
  #solutionSystemContent .content {
    bottom: -120px;
    height: 205px;
  }
  #solutionSystemContent .ant-carousel .slick-slide {
    height: 440px;
  }
  #solutionSystemContent .contentTitleSolution h1 {
    font-size: 12px;
  }
  #solutionSystemContent .contentTitleSolution h2 {
    font-size: 18px;
    line-height: 28px;
  }
  #feature .contentItemFeature img {
    width: 60px;
  }
  .logo h1 {
    margin-left: -10px;
  }
  #bannerBodyFeature {
    height: 500px;
  }
  #bannerBodyFeature .contentHeaderFeature h1 {
    font-size: 18px;
  }
  #feature .contentFeature {
    margin-top: 20px;
  }
  #aboutSystemFeature {
    margin-top: 150px;
  }
  #aboutSystemFeature .imgLeftSystem img {
    height: 190px;
    top: 90px;
  }
  #aboutSystemFeature .itemContentRightSystem h1 {
    line-height: 30px;
    font-size: 18px;
  }
  #aboutSystemFeature .itemContentRightSystem p {
    padding: 0px 10px;
  }
  #aboutSystemFeature .itemContentRightSystem {
    margin-top: 150px;
  }
  #aboutMain .contentTitleHeaderAbout h1 {
    font-size: 20px;
  }
  #aboutMain {
    height: 500px;
  }
  #itemAboutMainDetail .itemContentDetailMain h2 {
    font-size: 15px;
  }
  #itemAboutMainDetail .itemContentDetailMain h1 {
    font-size: 14px;
  }
  #itemBorderRabowLeft .titleSevercieMain h2 {
    padding: 0px 10px;
  }
  #itemBorderRabowLeft .itemServiceAbout img {
    margin-top: 0px;
  }
  .btnLogin {
    margin-left: 0px;
  }
  #itemPriceAllMqn .titleItemTalbePrice h2 {
    font-size: 18px;
  }
  #itemPriceAllMqn .titleItemTalbePrice h1 {
    font-size: 14px;
  }
  #itemPriceAllMqn .itemInfoContentMain p {
    padding: 0px;
  }
  #itemPriceAllMqn .itemInfoContentMain {
    margin-left: 0px;
  }
  #itemFeedbackCustomer {
    margin-top: 50px;
  }
  #customerHeaderProMain .itemTitleCustomer p {
    padding: 0px 20px;
  }
  #itemSystemAllTutorial {
    margin-top: 30px;
  }
  #itemSystemAllTutorial .itemTitleAllTutorial h2 {
    font-size: 20px;
    padding: 0px 24px;
  }
  #itemSystemAllTutorial .itemTitleAllTutorial h1 {
    font-size: 14px;
  }
  #ContactMainMqn .itemInfoContactMain p {
    width: 100%;
  }
  #itemHeaderContactMain .itemTitleCustomer p {
    padding: 0px 20px;
  }
  #questionMainProMqn {
    padding: 0px 10px;
  }
  #questionMainProMqn .titleQestionMain h1 {
    font-size: 24px;
  }
  #border2Main {
    height: 200px;
    margin-bottom: -120px;
  }
  .btnLoginMenu {
    margin: 0 auto;
  }
  #headerTrialMain {
    margin-bottom: 0px;
    height: 100%;
  }
  #headerTrialMain .contentTitleHeaderAbout h1 {
    margin-top: 0px;
    padding: 20px;
  }
  .card-horizonal .ant-card-cover {
    width: 40%;
  }
  .card-horizonal .ant-card-body {
    width: 60%;
    padding: 16px;
  }
}
@media (max-width: 320px) {
  #headerMain .BtnStartTest,
  #headerMain .btnLogin {
    width: 118px;
    height: 45px;
    font-size: 14px;
  }
  #aboutMeMain .videoYoutube iframe {
    width: 100%;
    height: 200px;
    margin-left: 6px;
  }
  #priceTablePro .itemTablePriceProMqn {
    width: 100%;
  }
  #FooterMain .customFormFooter {
    width: 185px;
  }
  #priceTablePro .itemTablePriceProMqn {
    width: 320px;
  }
  #itemAboutMainDetail .itemImgAboutDetail img {
    margin-left: 25px;
  }
  #aboutMain .contentTitleHeaderAbout h1 {
    padding: 0px 20px;
  }
  #itemAboutMainDetail .itemContentDetailMain h2 {
    font-size: 14px;
  }
  #itemAboutMainDetail .itemContentDetailMain h1 {
    font-size: 12px;
  }
  #itemHeaderContactMain {
    height: 500px;
  }
  #itemHeaderContactMain .itemTitleCustomer h1 {
    padding: 0px 10px;
  }
  #questionMainProMqn {
    padding: 0px 10px;
  }
  #questionMainProMqn .titleQestionMain h1 {
    font-size: 18px;
  }
  #detailInfoContentSupportMqn {
    padding: 0px 10px;
  }
  #detailInfoContentSupportMqn .titleSupportDetail h1 {
    letter-spacing: 0px;
  }
  #aboutSystemFeature .imgLeftSystem img {
    height: 165px;
    top: 115px;
  }
  #bannerBodyFeature {
    height: 545px;
  }
  #bannerBodyFeature .contentHeaderFeature h1 {
    font-size: 14px;
  }
  #itemPriceAllMqn {
    margin-top: 30px;
  }
  #itemPriceAllMqn .itemPriceTable1 {
    padding: 20px 10px;
  }
  #HeaderPriceMain .itemContentHeaderPrice {
    margin-top: 150px;
  }
  #customerHeaderProMain .itemTitleCustomer {
    margin-top: 130px;
  }
  #headerTrialMain {
    margin-bottom: 0px;
  }
  #solutionSystemContent .ant-carousel .slick-slide {
    height: 360px;
  }
}
